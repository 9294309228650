<template>
  <div class="menus">
    <div id="" v-hasPermi="['system:user:list']">
      <el-form
        ref="queryForm"
        :inline="true"
        label-width="40px"
        style="width: 100%; text-align: left"
      >
        <el-form-item label="名称" prop="roleName">
          <el-input
            v-model="resetuserName"
            placeholder="请输入名称"
            clearable
            size="small"
            style="width: 200px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>

        <el-form-item label="电话" prop="roleKey">
          <el-input
            v-model="resetphone"
            placeholder="请输入电话"
            clearable
            size="small"
            style="width: 200px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>

        <el-form-item label="状态" prop="status">
          <el-select
            v-model="resetstatus"
            placeholder="请选择"
            clearable
            size="small"
            style="width: 200px"
          >
            <el-option value="0" label="正常"> </el-option>
            <el-option value="1" label="停用"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="" prop="status">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            v-hasPermi="['system:user:list']"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            v-hasPermi="['system:user:list']"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="tian">
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        size="mini"
        @click="addition()"
        style="float: left"
        v-hasPermi="['system:user:add']"
        >添加
      </el-button>
    </div>

    <!-- ---------------添加模块----------------------------------- -->
    <template>
      <el-dialog
        title="添加管理员"
        :visible.sync="dialogFormVisible"
        width="600px"
        append-to-body
        @close="clearSelectSchool"
      >
        <el-form ref="addForm" :model="addForm" :rules="rules">
          <el-form-item
            label="部门ID"
            :label-width="formLabelWidth"
            prop="deptId"
          >
            <treeselect
              v-model="addForm.deptId"
              :options="deptOptions"
              :normalizer="normalizer"
              placeholder="选择上级部门"
            />
          </el-form-item>
          <el-form-item
            label="角色"
            :label-width="formLabelWidth"
            prop="roleId"
          >
            <el-select
              v-model="addForm.roleId"
              placeholder="请选择"
              style="width: 100%"
              @change="refreshData"
            >
              <el-option
                v-for="item in roleList"
                :key="item.roleId"
                :label="item.roleName"
                :value="item.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理学校" :label-width="formLabelWidth" prop="school">
            <el-tree
              :data="querySchool"
              show-checkbox
              ref="school"
              node-key="id"
              :props="defaultProps"
              default-expand-al="expand"
              highlight-current
              @check-change="schoolChange"
            ></el-tree>
          </el-form-item>
          <el-form-item
            label="登录名称"
            :label-width="formLabelWidth"
            prop="loginName"
          >
            <el-input
              v-model="addForm.loginName"
              placeholder="请输入登录名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="登录密码"
            :label-width="formLabelWidth"
            prop="password"
          >
            <el-input
              v-model="addForm.password"
              type="password"
              placeholder="请输入登录密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="用户姓名"
            :label-width="formLabelWidth"
            prop="userName"
          >
            <el-input
              v-model="addForm.userName"
              placeholder="请输入用户姓名"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="邮箱"
            :label-width="formLabelWidth"
            prop="email"
            :rules="[
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              {
                type: 'email',
                message: '请输入正确的邮箱地址',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              v-model="addForm.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号"
            :label-width="formLabelWidth"
            prop="phonenumber"
          >
            <el-input
              v-model="addForm.phonenumber"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth" prop="sex">
            <el-radio-group v-model="addForm.sex">
              <el-radio label="0">男</el-radio>
              <el-radio label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="状态"
            :label-width="formLabelWidth"
            prop="status"
          >
            <el-radio-group v-model="addForm.status">
              <el-radio label="0">正常</el-radio>
              <el-radio label="1">停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="editForm.remark"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
        <el-button @click="clearSelectSchool">取 消</el-button>
          <el-button type="primary" @click="submitForm()">确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <!-- ---------------编辑模块-------------------:default-checked-keys =""---------------- -->
    <template>
      <el-dialog
        title="编辑管理员"
        :visible.sync="editFormVisible"
        width="600px"
        append-to-body
        @close="clearSelectSchool"
      >
        <el-form ref="editForm" :model="editForm" :rules="rules">
          <el-form-item label="部门ID" :label-width="formLabelWidth" prop="deptIdss">
            <treeselect
              v-model="editForm.deptIdss"
              :options="deptOptions"
              :normalizer="normalizer"
              placeholder="选择上级部门"
            />
          </el-form-item>
          <el-form-item label="角色" :label-width="formLabelWidth" prop="roleId">
            <el-select
              v-model="editForm.roleId"
              placeholder="请选择"
              style="width: 100%"
              @change="refreshData"
            >
              <el-option
                v-for="ites in roleList"
                :key="ites.roleId"
                :label="ites.roleName"
                :value="ites.roleId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="管理学校" :label-width="formLabelWidth" prop="school">
            <el-tree
              :data="querySchool"
              show-checkbox
              ref="school"
              node-key="id"
              :props="defaultProps"
              default-expand-al="expand"
              :default-checked-keys ="checkedSchools"
              highlight-current
              @check-change="schoolChange"
            ></el-tree>
          </el-form-item>
          <el-form-item label="用户姓名" :label-width="formLabelWidth" prop="userName">
            <el-input
              v-model="editForm.userName"
              placeholder="请输入用户姓名"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="邮箱"
            :label-width="formLabelWidth"
            prop="email"
            :rules="[
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              {
                type: 'email',
                message: '请输入正确的邮箱地址',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input
              v-model="editForm.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" :label-width="formLabelWidth" prop="phonenumber">
            <el-input
              v-model="editForm.phonenumber"
              placeholder="请输入手机号"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="性别" :label-width="formLabelWidth" prop="sex">
            <el-radio-group v-model="editForm.sex">
              <el-radio label="0">男</el-radio>
              <el-radio label="1">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
            <el-radio-group v-model="editForm.status">
              <el-radio label="0">正常</el-radio>
              <el-radio label="1">停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input
              v-model="editForm.remark"
              type="textarea"
              placeholder="请输入备注"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="clearSelectSchool">取 消</el-button>
          <el-button type="primary" @click="redactSubmint()">确 定</el-button>
        </div>
      </el-dialog>
    </template>

    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
    >
      <el-table-column prop="userId" label="用户编号"> </el-table-column>
      <el-table-column prop="loginName" label="登录名称"> </el-table-column>
      <el-table-column prop="userName" label="用户姓名"> </el-table-column>
      <el-table-column prop="deptName" label="部门"> </el-table-column>
      <el-table-column prop="phonenumber" label="电话"> </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tag type="primary" plain v-if="scope.row.status === '0'"
            >正常</el-tag
          >
          <el-tag type="danger" plain v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"> </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope" v-if="scope.row.userId !== 1">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
            v-hasPermi="['system:user:edit']"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
            v-hasPermi="['system:user:remove']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      center
      background
      layout="prev, pager, next, sizes, total, jumper"
      :page-sizes="pageSizes"
      :page-size="pagesize"
      :page-count="pageCount"
      :total="total"
      :current-page.sync="pageNo"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      ref="pagination"
      @next-click="pages"
      @prev-click="pages"
    >
    </el-pagination>
  </div>
</template>

<script>
import request from '../../utils/request.js'
import { schoolList } from '@/api/user/shcool'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect },
  data() {
    return {
      pagesize: 10, //分页默认一页显示15条数据
      currpage: 1, //分页默认显示第1页数据
      pageSizes: [10, 30, 50], //分页可选择一页多少条数据
      pageNo: 1, //强行第一页
      total: 0, //共多少条数据从接口获取
      pageCount: 0, //共多少页数据从接口获取
      formLabelWidth: '100px',
      tableData: [],
      checkedKeys: [],
      // 部门树选项
      deptOptions: [],

      dialogFormVisible: false,
      editFormVisible: false,
      addForm: {
        deptId: undefined,
        roleId: '',
        loginName: '',
        password: '',
        userName: '',
        email: '',
        phonenumber: '',
        sex: '',
        status: '',
        remark: '',
      },
      editForm: {},
      resetuserName: '', //搜索名称
      resetphone: '', //搜索电话
      resetstatus: '', //搜索状态
      deptIdsss: '',
      loginName: '',
      userName: '',
      email: '',
      phonenumber: '',
      sex: '',
      password: '',
      status: '', //状态
      remark: '', //备注
      roleId: '',
      roleList: [],
      treeData: '',
      treeDataValue: '',
      mineStatus: '',
      mineStatusValue: [],
      clickMsg: '1', //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
      defaultProps: {
        children: 'children',
        label: 'title',
      },
      // 表单校验
      rules: {
        deptId: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        deptIdss: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        roleId: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        loginName: [
          { required: true, message: '请输入登录名称', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
        ],
        // email:[
        //   { required: true, message: "请输入邮箱", trigger: "blur" }
        // ],
        phonenumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 手机号格式
          {
            required: true,
            pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
      },
      // 搜索学校树
      querySchool: [],
      form: {},
      menuExpand: false,
      menuNodeAll: false,
      selectSchool:[],
      checkedSchools:[]
    }
  },
  created() {
    this.info()
    this.getSchoolOptions()
  },
  methods: {
    // 清空选中学校
    clearSelectSchool(){
      this.$refs.school.setCheckedKeys([])
      this.dialogFormVisible = false
      this.editFormVisible = false
    },
    // 查询学校数据
    getSchoolOptions() {
      schoolList().then((res) => {
        this.querySchool = this.handleTree(res.data, 'id', 'parentid')
      })
    },
    schoolChange(){
      this.selectSchool = this.$refs.school
        .getHalfCheckedKeys()
        .concat(this.$refs.school.getCheckedKeys())
    },
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children,
      }
    },
    handleCheckChange() {
      this.mineStatusValue = this.$refs.tree
        .getHalfCheckedKeys()
        .concat(this.$refs.tree.getCheckedKeys())
    },
    bianCheckChange() {
      this.mineStatusValue = this.$refs.bian
        .getHalfCheckedKeys()
        .concat(this.$refs.bian.getCheckedKeys())
    },

    refreshData(val) {
      this.$forceUpdate()
    },
    //获取列表数据
    info() {
      this.clickMsg = '1' //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
      let dats = {
        pageNum: this.currpage,
        pageSize: this.pagesize,
      }
      return request({
        url: 'admin/list',
        method: 'get',
        params: dats,
      }).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data.data
          this.pageCount = res.data.pageBean.pageCount //接口获取到的共多少页
          this.total = res.data.pageBean.count //接口获取到的共多少条
        }
      })
    },
    unpad() {
      return request({
        url: 'dept/selectDeptList',
        method: 'get',
      }).then((res) => {
        if (res.status == 200) {
          this.deptIdsss = undefined
          this.deptOptions = this.handleTree(res.data, 'deptId')
          // console.log(this.deptOptions)
        }
      })
    },
    addition() {
      this.resetForm('addForm')
      this.dialogFormVisible = true
      this.unpad()
      this.roleDrop()
    },
    //清空添加表单
    empty() {
      this.deptIdsss = ''
      this.loginName = ''
      this.userName = ''
      this.email = ''
      this.phonenumber = 0
      this.checkedKeys = []
      this.sex = ''
      this.password = ''
      this.status = ''
      this.remark = ''
      this.roleId = ''
    },
    //搜索
    handleQuery() {
      this.clickMsg = '2' //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页
      let dats = {
        pageNum: this.currpage,
        pageSize: this.pagesize,
        username: this.resetuserName,
        phone: this.resetphone,
        status: this.resetstatus,
      }
      return request({
        url: 'admin/list',
        method: 'get',
        params: dats,
      }).then((res) => {
        if (res.status == 200) {
          this.tableData = res.data.data
          this.pageCount = res.data.pageBean.pageCount //接口获取到的共多少页
          this.total = res.data.pageBean.count //接口获取到的共多少条
        }
      })
    },
    //搜索重置
    resetQuery() {
      ;(this.resetuserName = ''), //搜索名称
        (this.resetphone = ''), //搜索电话
        (this.resetstatus = '') //搜索状态
      this.info()
    },
    // 添加弹窗确定提交
    submitForm() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          if(this.selectSchool.lenth == 0){
            this.$message.error("请选择管理学校")
            return
          }else{
            this.addForm.schoolIdList = this.selectSchool.join(',')
          }
          // if (this.addForm.userId != undefined) {
          // } else {
          return request({
            url: 'admin/inertAdmin',
            method: 'post',
            data: this.addForm,
          })
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: '添加成功',
                  type: 'success',
                })
                this.info()
                this.empty()
                this.dialogFormVisible = false
              }
            })
            .catch(() => {})
        }
        // }
      })
    },

    //编辑弹窗
    handleEdit(index, row) {
      this.checkedSchools = []
      this.editForm = Object.assign({}, row)
      if(this.editForm.schoolIdList){
        this.checkedSchools = this.editForm.schoolIdList.split(",")
        this.selectSchool = this.checkedSchools
      }
      this.unpad()
      this.roleDrop()
      //  console.log(this.editForm)
      this.fulLine(this.editForm.userId)
      let dats = {
        adminId: row.userId,
      }
      return request({
        url: 'admin/selectAdminById',
        method: 'get',
        params: dats,
      }).then((res) => {
        if (res.status == 200) {
          // this.info();
          // this.empty();
          this.editForm.deptIdss = res.data.dept_id
          this.editForm.roleId = res.data.role_id
          this.editFormVisible = true
        }
      })
    },
    //编辑获取角色数据
    roleDrop() {
      return request({
        url: 'role/roleDropDown',
        method: 'get',
      }).then((res) => {
        if (res.status == 200) {
          this.roleList = res.data
          // this.checkedKeys = res.data.checkedKeys
          // console.log(this.roleList)
        }
      })
    },
    //编辑获取整行数据
    fulLine(id) {
      let datas = {
        roleId: id,
      }
      return request({
        url: 'menu/roleMenuTreeSelect',
        method: 'get',
        params: datas,
      }).then((res) => {
        if (res.status == 200) {
          this.checkedKeys = res.data.checkedKeys
        }
      })
    },
    // 编辑确定
    redactSubmint() {
      console.log(this.selectSchool.length == 0);
      this.$refs['editForm'].validate((valid) => {
        console.log(valid);
        if(valid){
          if(this.selectSchool.length == 0){
            this.$message.error("请选择管理学校")
            return
          }else{
            this.addForm.schoolIdList = this.selectSchool.join(',')
            this.clickMsg = '3'
            let datas = {
              userId: this.editForm.userId,
              deptId: this.editForm.deptIdss,
              schoolIdList:this.selectSchool.join(','),
              userName: this.editForm.userName,
              email: this.editForm.email,
              phonenumber: this.editForm.phonenumber,
              sex: this.editForm.sex,
              status: this.editForm.status,
              remark: this.editForm.remark,
              roleId: this.editForm.roleId,
            }
            return request({
              url: 'admin/update',
              method: 'put',
              data: datas,
            })
              .then((res) => {
                if (res.status == 200) {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                  })
                  this.info()
                  this.mineStatusValue = []
                  this.editFormVisible = false
                }
              })
              .catch(() => {})
                }
              }
            })
    },
    //删除
    handleDelete(index, row) {
      // console.log(index, row);
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            adminId: row.userId,
          }
          return request({
            url: 'admin/deleteAdminById',
            method: 'delete',
            data: data,
          }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: '删除成功',
                type: 'success',
              })
              this.info()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    pages() {
      if (this.clickMsg == '1') {
        //如果 clickMsg = 1 的话
        this.info() //分页查询所有数据
      } else if (this.clickMsg == '2') {
        //如果 clickMsg = 2 的话
        this.inquiry() //分页查询搜索数据
      } else if (this.clickMsg == '3') {
        //如果 clickMsg = 3 的话
        this.redactSubmint() //分页角色查询数据
      }
    },

    /* 分页方法-------------- */
    handleCurrentChange(cpage) {
      this.currpage = cpage
      this.pages() //查询接口渲染数据
    },
    handleSizeChange(psize) {
      this.pagesize = psize
      this.pages() //查询接口渲染数据
    },
  },
}
</script>

<style scoped>
::v-deep .el-dialog__body{
  height: 60vh;
  overflow: auto;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.customWidth {
  width: 550px;
}
.el-form {
  height: auto;
}
.el-pagination {
  text-align: right;
}
</style>
